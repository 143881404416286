@media (max-width: 1024px) {
    .my-custom-modal-css {
        --height: 100%;
        --width: 100%;
    }

    .modal-tag-list-css {
        --width: 100% !important;
        --height: 100% !important;
    }

    .progression-step-form-modal {
        --width: 100% !important;
        --height: 100% !important;
    }

    .full-width-modal {
        --width: 100% !important;
        --height: 100% !important;
    }
}

.full-height-modal {
    --width: var(--curafida-login-wrapper-width);
    --height: 100% !important;
}

@media (min-width: 1024px) {
    .modal-wrapper {
        border-radius: 5px !important;
    }
    .my-custom-modal-css {
        //--height: 100%;
        --width: 100%;
    }

    .modal_measurement_input {
        --width: 35%;
        --height: 95%;
        --max-height: 700px;
        --max-width: 450px !important;
    }

    .modal-tag-list-css {
        --width: var(--curafida-wrapper-width) !important;
        --height: 700px !important;
    }

    .progression-step-form-modal {
        --width: var(--curafida-login-wrapper-width);
        --height: 800px;
    }

    .modal-norm-value-list-css .modal-wrapper {
        position: absolute;
        display: block;
        width: 550px;
        height: 300px !important;
        max-height: 34% !important;
        min-height: 300px !important;
    }
}

.modal-pictures-css {
    --width: 675px !important;
    --height: 550px !important;
}

.modal-training-exercise-css {
    --width: 675px !important;
    --height: 525px !important;
}

@media (min-width: 751px) and (max-width: 1350px) {
    .modal-standard-ionic {
        --width: 600px !important;
        --height: 500px !important;
    }
}
.modal-dico-chatbot-overlay-css {
    --width: 70% !important;
    --height: 70% !important;
    padding: 1rem;
    display: flex;
}
.modal-dico-chatbot-info-css {
    --width: 50% !important;
    --height: 30% !important;
    padding: 1rem;
    display: flex;
}
.modal-dico-ai-info-css {
    --width: 40% !important;
    --height: 60% !important;
    padding: 1rem;
    display: flex;
}
.modal-training-child-exercise-css {
    --width: 675px !important;
    --height: 630px !important;
}

.modal-tags-css {
    --width: 675px !important;
    --height: 250px !important;
}

.modal-create-exercise-css {
    // position: absolute;
    --width: 675px !important;
    --height: 450px !important;
}

.modal-media-exercise-css {
    --width: 675px !important;
    --height: 850px !important;
}

.modal-create-exercise-comment-css {
    // position: absolute;
    --width: 675px !important;
    --height: 300px !important;
}

.modal-information {
    --width: 450px;
    --height: 230px;
    --backdrop-opacity: 0.5 !important;
}

.modal-information-expanding {
    --width: 450px;
    --height: 300px;
    --backdrop-opacity: 0.5 !important;
}

.modal-text-input {
    --width: 500px;
    --height: 350px;
}

.modal-text-input-reduce {
    --width: 500px;
    --height: 250px;
}

.modal-information-reduced {
    --width: 30%;
    --height: 30%;
    --backdrop-opacity: 0.5 !important;
}

.modal-discover-devices {
    --width: 50%;
    --height: 40%;
    --max-height: 500px;
    --max-width: 600px;
}

.full-width-modal-css {
    --width: var(--curafida-login-wrapper-width);
}

.confirm-appointment-width-modal-css {
    --width: var(--curafida-login-wrapper-width);
    --height: 500px;
}

.training-action-sheet-class .action-sheet-title {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}

.modal-standard {
    --width: 500px;
    --height: 350px;
}

.btn-modal {
    width: 10rem;
}

.footer-md::before {
    display: none;
}

.modal-requirement-information-css {
    --width: 510px;
    --height: 600px;
}

.modal-webshop-information-css {
    --width: 510px;
    --height: 450px;
}

.modal-finished-information-css {
    --width: 510px;
    --height: 550px;
}

.kiosk-modal {
    --width: 700px;
    --height: 470px;
}

.kiosk-start-task-modal {
    --width: 700px;
    --height: 670px;
}

.kiosk-task-modal {
    --width: 560px;
    --height: 520px;
}

.full-screen-modal {
    --width: 100%;
    --height: 100%;
}

.feedback-modal {
    --width: 90%;
    --height: 550px;
}

.feedback-borg-modal {
    --width: 90%;
    --height: 400px;
}

.feedback-modal-small {
    --width: 90%;
    --height: 350px;
}

.full-width-modal {
    --width: var(--curafida-login-wrapper-width);
    --height: 600px;
}

.extended-height-modal {
    --width: var(--curafida-login-wrapper-width);
    --height: 670px;
}

.reduced-height-modal {
    --width: var(--curafida-login-wrapper-width);
    --height: 350px;
}

ion-modal.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}
