@font-face {
    font-family: 'Source Sans Pro';
    src:
        url('../assets/fonts/SourceSansPro-Regular.woff2') format('woff2'),
        url('../assets/fonts/SourceSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src:
        url('../assets/fonts/nunito-latin-400-normal.woff2') format('woff2'),
        url('../assets/fonts/nunito-latin-400-normal.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

:root {
    --ion-color-card: #c2d5f1;
    --ion-color-card-rgb: 194, 213, 241;
    --ion-color-card-contrast: #000000;
    --ion-color-card-contrast-rgb: 0, 0, 0;
    --ion-color-card-shade: #abbbd4;
    --ion-color-card-tint: #c8d9f2;

    --ion-color-button: #fdc49f;
    --ion-color-button-rgb: 253, 196, 159;
    --ion-color-button-contrast: #003556;
    --ion-color-button-contrast-rgb: 0, 53, 86;
    --ion-color-button-shade: #dfac8c;
    --ion-color-button-tint: #fdcaa9;
}

.ion-color-new {
    --ion-color-base: var(--ion-color-new);
    --ion-color-base-rgb: var(--ion-color-new-rgb);
    --ion-color-contrast: var(--ion-color-new-contrast);
    --ion-color-contrast-rgb: var(--ion-color-new-contrast-rgb);
    --ion-color-shade: var(--ion-color-new-shade);
    --ion-color-tint: var(--ion-color-new-tint);
}

.ion-color-card {
    --ion-color-base: var(--ion-color-card) !important;
    --ion-color-base-rgb: var(--ion-color-card-rgb) !important;
    --ion-color-contrast: var(--ion-color-card-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-card-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-card-shade) !important;
    --ion-color-tint: var(--ion-color-card-tint) !important;
}

.ion-color-button {
    --ion-color-base: var(--ion-color-button) !important;
    --ion-color-base-rgb: var(--ion-color-button-rgb) !important;
    --ion-color-contrast: var(--ion-color-button-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-button-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-button-shade) !important;
    --ion-color-tint: var(--ion-color-button-tint) !important;
}

.dico-ion-button {
    font-family:
        Source Sans Pro,
        serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #003556;
    --border-style: none;
    --border-radius: 56px;
    text-transform: initial;
    white-space: pre-wrap;
}

.dico-ion-router-outlet-toolbar-vertical {
    margin-left: 10%;
    width: 100%;
    max-width: calc(100% - 100px);
}

.dico-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    max-width: 75%;
    font-family: 'Source Sans Pro', serif;
}

.dico-toolbar-vertical {
    width: 10vw;
    max-width: 100px;
    height: 100%;
    background: var(--ion-color-primary) !important;
    border-top-right-radius: 50px;
    border-top-color: var(--ion-background-color);
    border-top-width: 1px;
}

.dico-card-botchat-right-QR {
    //width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid #ecf1f2;
    box-sizing: border-box;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.14),
        0 3px 4px rgba(0, 0, 0, 0.12),
        0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 0 20px;
    color: #005f83 !important;
}

.dico-width-10 {
    padding-right: 5rem;
}

.dico-alert {
    position: absolute;
    width: 800px;
    height: 449.19px;
    left: 282px;
    top: 149px;
    background: #ffffff !important;
}

.dico-form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.dico-form-control:focus {
    border: 1px solid #80bdff !important;
}
.wrapper-without-height-main {
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;
    --padding: 0 !important;
    max-width: var(--curafida-wrapper-width);
}
.dico-ion-padding {
    --padding-start: var(--ion-padding, 16px);
    --padding-end: var(--ion-padding, 16px);
    --padding-top: var(--ion-padding, 100px);
    --padding-bottom: var(--ion-padding, 16px);
    padding: var(--ion-padding, 100px) var(--ion-padding, 16px) var(--ion-padding, 16px);
}

.dico-email-textarea {
    border: 1px solid #0d3c5f;
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-left: 4px;
    background-color: #ffffff;
    margin-bottom: 20px;
    /* Dark blue */
    color: #003556;
    /* padding */
    --padding-bottom: 15px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    --padding-top: 15px !important;
}

.fine-granular-grid {
    --ion-grid-columns: 24;
}

dico-ion-header {
    background: #fff !important; // var(--ion-color-primary) !important;
}

.dico-flex-container-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.dico-chatbot-info-modal {
    background-color: #ffffff;
    padding-left: 1rem;
    //padding-right: 1rem;
}

.dico-chatbot-info-modal-content {
    height: 200px;
}

.dico-chatbot-modal-content {
    height: 500px;
    width: 500px;
}

.dico-dashed-line {
    border-style: dashed;
    border-color: #7baabc;
    border-bottom-width: 6px;
    border-top-width: 0;
    //transform: rotate(180deg);
}

.dico-knowladge-table {
    border: 1px solid #7baabc;
    margin-top: 2em;
    margin-right: 2em;
    margin-bottom: 2em;
    background-color: white;
    border-radius: 1em;
    // padding:1em;
}

.dico-priority-card {
    border: 1px solid #7baabc;
    margin: 1em;
    background-color: white;
    border-radius: 1em;
    // padding:1em;
}

.dico-knowladge-card-img {
    max-width: 100%;
    border: 0;
    height: 100%;
    align-self: center;
}

.dico-knowledge-course-card-img {
    max-width: 100%;
    border: 0;

    align-self: center;
    position: absolute;
    clip: rect(2rem, 18rem, 12rem, 0rem);
}

.dico-knowledge-course-card-content {
    height: 15rem;
}

.dico-knowledge-media-card-content {
    height: 29rem;
}

.dico-tech-card-img {
    max-width: 100%;
    border: 0;
    margin: 20px;
    // align-self: center;
}

.dico-card {
    background: white;
    width: 12rem;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #005f83 !important;
    margin-right: 1rem;
}

.dico-radio-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 14px;
    width: 194.5px;
    min-height: 125px;
    background: #ffffff;
    border: 1px solid #ecf1f2;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.14),
        0 3px 4px rgba(0, 0, 0, 0.12),
        0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 0 20px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    color: #005f83 !important;
    /*background: white;
    width: 12rem;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #005F83 !important;
    margin-right: 1rem;*/
}

.dico-card-download {
    background: white;
    width: 20rem;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #005f83 !important;
    margin-right: 1rem;
}

.dico-card-technology {
    background: white;
    width: 60rem;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #003556 !important;
    margin-right: 1rem;
}

.text-discreet {
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #7baabc;
}

.dico-text-input {
    background: #ffffff;
    border: 1px solid #005f83;
    box-sizing: border-box;
    border-radius: 8px;
}

.dico-card-right {
    border-radius: 30px 30px 0 30px;
}

.dico-card-left {
    border-radius: 30px 30px 30px 0;
}

.dico-ion-item {
    background: #ffffff !important;
    box-shadow: #ffffff;
    border-radius: 0;
    border: none;
}

.dico-fab-button {
    width: 2rem;
    height: 2rem;
}

.dico-radio {
    //--border-radius:50%;
    background-color: #ecf1f2 !important;
    //  --inner-border-radius: 50%;
    --color-checked: #005f83 !important;
    --color: #005f83 !important;
}

.dico-vertical-hr {
    width: 48px;
    height: 0;
    //top: 0px;
    //margin-left: rem;
    transform: rotate(90deg);
    //position: absolute;
    // left: 130px;
    border: 1px dashed #7baabc;
}

.dico-horizontal-hr {
    width: 95%;
    height: 0;
    border: 1px solid #7baabc;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.dico-card-checkbox {
    --background-checked: #005f83;
    --border-color-checked: #005f83;
    background: #dadada !important;
}

.dialog-normal {
    color: #003556 !important;
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 25px;
    // padding-bottom: 25px;
}

.dialog-black {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 25pt !important;
    line-height: 30pt;
}

.dialog-small {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18pt !important;
    line-height: 22pt;
}

.dialog-normal-subline {
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    color: #003556;
}
.dialog-normal {
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
}

.dialog-normal-subline-16 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18px;
    color: #003556;
}

.dialog-normal-black {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px !important;
    line-height: 25px;

    /* Dark blue */

    color: #003556;
}

.dialog-highlight {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 30px;
}

.dico-typography {
    font-family:
        Source Sans Pro,
        serif;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 18px;
    font-style: normal;
    color: #003556;
}

.dico-typography-14 {
    font-family:
        Source Sans Pro,
        serif;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 18px;
    font-style: normal;
    color: #003556;
}

.dico-footer {
    //padding-top: 35px;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px; /* Height of the footer */
    // background: #6cf;
    right: 40px;
    z-index: 1;
}

.dico-ai-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
    gap: 10px;
    height: 2.3rem;
    min-width: 10rem;
    --background: rgba(0, 53, 86, 0.12) !important;
    border-radius: 16px;
}

.dico-text-small-bold {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 20px;
}

.dico-query-last-col {
    border-left: solid 1px #0d3c5f;
}

.dico-textarea {
    border-bottom: solid 1px #003556 !important;
    border-top: none;
    border-right: none;
    border-left: none;
    background: #ecf1f2;
}

.dico-Handlungsdruck {
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #005f83;
}

.dico-text-normal {
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    color: #003556;
}

.dico-Dialog-normal-subline {
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    color: #003556;
}

.dico-text-small-normal {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    color: #003556 !important;
}

.dico-text-small-semibold {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 23px;
    //color: #003556;
}

.dico-text-label {
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 18px;
}

.dico-div-modal {
    padding-right: 2em;
    padding-bottom: 0.7rem;
}

.dico-h1 {
    font-family: 'Nunito', serif !important;
    font-size: 50px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: 68px !important;
    letter-spacing: 0 !important;
    text-align: left !important;
}

.dico-h2 {
    font-family: 'Nunito', serif !important;
    font-style: normal;
    font-weight: 800;
    font-size: 40px !important;
    line-height: 55px;
    color: #003556;
    padding-bottom: 50px;
}

.dico-h3 {
    font-family: 'Nunito', serif !important;
    font-style: normal;
    font-weight: 800;
    font-size: 30px !important;
    line-height: 41px;
    color: #003556;
    align-items: center;
    // padding-bottom: 50px;
}

.dico-h4 {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px !important;
    line-height: 30px;
    color: #003556 !important;
}
.dico-plus-16 {
    font-family: "Nunito", serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    letter-spacing: 0 !important;
    text-align: left !important;
}

dico {
    ion-icon {
        font-size: 24px;
    }
}

.dico-vertical-menu-label {
    font-size: 10px !important;
    font-family: 'Source Sans Pro', serif;
}
