.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: default !important;
}
.no-click {
    pointer-events:none; //This makes it not clickable
}

ion-router-outlet {
    top: var(--roulet-height);
}

:root {
    --roulet-height: 44px !important;
}

ion-icon {
    pointer-events: none;
}

.searchbar-input.sc-ion-searchbar-md {
    padding-inline-start: 40px !important;
    padding-inline-end: 10px !important;
}

.font-weight-bold {
    font-weight: bold;
}

//swiper
.swiper-pagination-bullet-active {
    background-color: var(--ion-color-primary);
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--ion-color-primary) !important;
}

.swiper .swiper-slide {
    height: unset !important;
}

ion-footer {
    background: white;
}

.primary-color {
    color: var(--ion-color-primary) !important;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.word-breaking {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: break-spaces;
}

.media-background {
    background: #eeeeee;
    --background: #eeeeee;
}

.title-header {
    font-weight: bold;
    font-size: 120% !important;
}
.title-primary {
    font-weight: bold;
    font-size: 120% !important;
    color: var(--ion-color-primary);
}

.background-light {
    background: var(--ion-color-light);
}
.background-primary {
    background: var(--ion-color-primary);
    --background: var(--ion-color-primary);
}
.background-primary-contrast {
    background: var(--ion-color-primary-contrast);
    --background: var(--ion-color-primary-contrast);
}
.color-primary {
    color: var(--ion-color-primary);
    --color: var(--ion-color-primary);
}
.color-primary-contrast {
    color: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary-contrast);
}


.fc-col-header-cell-cushion {
    font-weight: normal !important;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.separator-border-bottom {
    border-bottom: 1px solid black;
    margin-bottom: 8px;
}

.no-vertical-margin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.no-left-margin{
    margin-left: 0 !important;
}

.no-border {
    border: none;
}


.text-standard-input-size {
    font-size: 14px !important;
}
.main-content {
    --padding-start:var(--ion-padding, 1rem);
    --padding-end:var(--ion-padding, 1rem);
    --padding-top:var(--ion-padding, 1rem);
    --padding-bottom:var(--ion-padding, 1rem);
    padding: var(--ion-padding, 1rem);
}