.item-input-width {
    margin-bottom: 5px;
    width: 50%;
}

.item-input-full-width {
    margin-bottom: 5px;
    width: 100%;
}

.item-label-user-info {
    min-width: 50%;
    max-width: 50%;
    font-weight: bold;
    font-size: 14px !important;
}

.error-message {
    font-size: 0.7em;
    color: var(--ion-color-danger);
    text-align: start;
    width: 100%;
    position: relative;
    margin-left: 0;
    margin-right: auto;
}

.item-input-modal-width {
    min-width: 60%;
}

.native-input[disabled].sc-ion-input-md {
    opacity: 1 !important;
}

.native-input[disabled].sc-ion-input-ios {
    opacity: 1 !important;
}

.native-textarea[disabled].sc-ion-textarea-md {
    opacity: 1 !important;
}
.native-textarea[disabled].sc-ion-textarea-ios {
    opacity: 1 !important;
}

.native-input[disabled].sc-ion-select-md {
    opacity: 1 !important;
}
.native-input[disabled].sc-ion-select-ios {
    opacity: 1 !important;
}

.opacity-full {
    opacity: 1;
}

.ion-label-color-disabled {
    // opacity: 0.55 ;
    align-self: flex-start;
}
.ion-label-color-enabled {
    opacity: 1 !important;
    align-self: flex-start;
}

ion-select {
    opacity: 1 !important;
    color: black;
}

.select-placeholder {
    opacity: 1 !important;
    color: black !important;
}

.ion-item-box-enable {
    border: 1px solid black !important;
    border-radius: 3px;
}
.ion-item-box-disable {
    border: 1px solid var(--ion-color-light) !important;
    border-radius: 3px;
}

.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
    opacity: 0.55;
}

.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
    opacity: 0.55;
}

.border-color-primary {
    border: 1.5px solid var(--ion-color-primary) !important;
}

.checkbox-input {
    margin: auto 16px auto 5px;
}
.checkbox-input-label {
    margin: auto;
    font-size: 14px !important;
    text-wrap: wrap;
}
