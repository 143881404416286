.kiosk-task-body {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-transform: initial;
    white-space: pre-wrap;
}

.kiosk-modal-button {
    height: 350px;
    width: 80%;
    padding-bottom: 20px;
    --border-radius: 10px;
}

.kiosk-modal-image-fix-height {
    height: 350px;
}

.kiosk-modal-button-without-width {
    height: 350px;
    padding-bottom: 20px;
    --border-radius: 10px;
}

.kiosk-task-icon {
    font-size: 200px;
}

.kiosk-task-icon-medium {
    font-size: 125px;
}

.kiosk-card-top-position {
    height: 40px;
}

.notification-button {
    --background: #ffffbf;
}

.announcement-button {
    --background: #e5e5e5;
}

.task-button {
    --background: #b7f2b2;
    background: #b7f2b2;
}

.training-balance-button {
    --background: #f5c07a;
    background: #f5c07a;
}

.training-strengthening-button {
    --background: #88bce3;
    background: #88bce3;
}

.training-relaxation-button {
    --background: #f1f112;
    background: #f1f112;
}

.video-appointment-button {
    --background: rgba(162, 40, 119, 0.15);
    background: rgba(162, 40, 119, 0.15);
}

.appointment-button {
    --background: #e8bad8;
    background: #e8bad8;
}

.notification-icon-container {
    background: #f0e36a;
}

.task-icon-container {
    background: #1a9934;
}

.training-balance-icon-container {
    background: #f49f1f;
}

.training-strengthening-icon-container {
    background: #1d78bd;
}

.training-relaxation-icon-container {
    background: #abab3d;
}

.video-appointment-icon-container {
    background: #a22877;
}

.appointment-icon-container {
    background: #e957b7;
}

.announcement-icon-container {
    background: #405866;
}

.task-border {
    border: 2px solid #1a9934;
}

.training-balance-border {
    border: 2px solid #f49f1f;
}

.training-strengthening-border {
    border: 2px solid #1d78bd;
}

.training-relaxation-border {
    border: 2px solid #f1f112;
}

.video-appointment-border {
    border: 2px solid #a22877;
}

.appointment-border {
    border: 2px solid #e957b7;
}

.kiosk-card-label {
    color: black;
    padding-left: 4px;
    height: 100%;
    text-align: start;
    max-width: 80%;
    word-break: break-word;
    letter-spacing: 0px;
}

.kiosk-other-day-label {
    word-break: break-word;
}

.kiosk-icon {
    font-size: 30px;
    color: white;
}

.icon-container {
    height: 45px;
    min-width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    border-radius: 15px;
}

.weekly-resume-padding-container {
    padding: 8px;
}

.kiosk-text {
    font-size: 20px !important;
}
